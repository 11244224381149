require('team-admin')
angular.module('team-admin')
  .directive('rosterDetail', function() {
    return {
      restrict: 'A',
      scope: {
        team: '=',
        roster: '=',
        context: '=',
        messagesEnabled: '=',
        removeFromList: '&'
      },
      template: require('./roster-detail.html'),
      controller: function(pageViewHandler, $q, $scope, $routeParams, $location, $log, DS, Player, Contact, _, i18ng, dialog, AlertPreference, Alerts, listFilter, renderContext, SNAP, launchDarklyFlags, preloadScript, ENV) {

        $scope.$watch('context', function(context) {
          if (!context) return
          $scope.TYPE = $scope.context.toUpperCase()
          $scope.metadataProperty = Player.contextTypeV3[$scope.context] + '_variables'
          $scope.gaPlayerType = $scope.context === 'players' ? 'Player' : 'Staff'
        })

        $scope.$watch(getPlayerId, getPlayer)
        $scope.$watchCollection('player.positions', setPositions)
        $scope.$watchGroup(['team', 'loadedPlayer'], setPlayer)
        $scope.SNAP = SNAP
        $scope.teamPermissionsLD = launchDarklyFlags.teamPermissions

        if ($scope.teamPermissionsLD) {
          preloadScript(`${ ENV.urls.sportAdminElements }/roster-persona-team-permissions-card-element/element.js`)
        }

        $scope.editPlayerModal = function() {
          dialog.confirm({
            directive: 'edit-player',
            attrs: {
              team: $scope.team,
              player: $scope.player,
              context: $scope.context,
              gaCategory: 'Roster',
              gaPlayerType: $scope.gaPlayerType
            }
          })
            .then(function(data) {
              Alerts.success(i18ng.t('PLAYERS.EDIT.success', { list: _.result(data.player, 'fullName') }))
            })
        }

        $scope.editContactPreferencesModal = function() {
          dialog.confirm({
            directive: 'edit-contact-preferences',
            attrs: {
              contacts: $scope.contacts,
              player: $scope.player
            }
          })
            .then(function(data) {
              $scope.contacts = data
              Alerts.success(i18ng.t('PLAYER.CONTACT_PREFERENCES.success'))
            })
        }

        $scope.newMessageModal = function() {
          pageViewHandler.fireEvent('Message.' + this.gaPlayerType, 8)
          dialog.confirm({
            directive: 'new-team-message',
            attrs: {
              team: $scope.team,
              roster: $scope.team.roster(), // $scope.roster passes undefined and does not get updated after load finishes
              recipients: [$scope.player.persona.persona_id],
              messagesEnabled: $scope.messagesEnabled
            }
          })
        }

        $scope.editPlayerPhotoModal = function() {
          dialog.confirm({
            directive: 'edit-player-photo',
            attrs: { player: $scope.player }
          })
            .then(function(player) {
              var msg = `ROSTER.EDIT_PHOTO.${ player.headshot ? '' : 'remove_' }success`
              Alerts.success(i18ng.t(msg, { player: $scope.player.name }))
            })
        }

        $scope.removePlayersModal = function() {
          dialog.confirm({
            directive: 'remove-players',
            attrs: {
              team: $scope.team,
              players: [$scope.player],
              context: $scope.context
            }
          })
            .then(removePlayer)
        }

        $scope.metadataAttrDisplay = function(name, value) {
          if (name == 'Date of Birth' && value) {
            return moment(value).format('MMM D YYYY')
          }
          else {
            return value
          }
        }

        function getPlayerId() {
          return $routeParams.playerId
        }

        function getPlayer(id) {
          $scope.loading = true
          loadPlayer(id)
            .then(loadSuccess)
            .then(loadAlertPreferences)
            .then(loadContactPreferences)
            .catch(loadError)
        }

        function loadPlayer(id) {
          var cachedPlayer = Player.get(id)
          return cachedPlayer ? $q.when(cachedPlayer) : Player.find(id, Player.v3Options)
        }

        function loadContactPreferences() {
          if ($scope.loadedPlayer.originator_system !== 'Ngin') return
          // return Contact.findAll({ playerId: $scope.loadedPlayer.id }, {
          //   url: `${ DS.adapters.se_api.defaults.basePath }/v3/roster_personas/${ $scope.loadedPlayer.id }/contacts`,
          //   ...Player.v3Options
          // })
          return Contact.findAll({ playerId: $scope.loadedPlayer.originator_id })
            .then($scope.setAs('contacts'), _.noop)
        }

        // Only really runs when player loaded from the URL
        function loadAlertPreferences(player) {
          $scope.alertPreferencesLoading = true
          AlertPreference.getMessagesEnabled($scope.team, [player])
            .then($scope.setAs('messagesEnabled'))
            .catch($log.error)
            .finally($scope.setAs('alertPreferencesLoading', false))
        }

        function loadSuccess(player) {
          return $scope.loadedPlayer = player
        }

        function loadError() {
          $scope.loading = false
        }

        function setPlayer() {
          var player = $scope.loadedPlayer
          var team = $scope.team
          var validPlayer = player && team && _.find(team.rosters, { id: player.roster_id })
          $scope.player = validPlayer ? player : null
          if (player && team) $scope.loading = false
        }

        function setPositions(positions) {
          positions = positions || []
          $scope.positions = positions.join(', ')
        }

        function returnToList() {
          renderContext.backto(renderContext.roster.next)
        }

        function removePlayer(deletedPlayers) {
          var removeFromList = $scope.removeFromList()
          if (removeFromList) removeFromList(deletedPlayers)
          else Alerts.success(listFilter(deletedPlayers, 'PLAYERS.REMOVE.success', { format: 'fullName' }))
          $location.path('/teams/' + $scope.team.id + '/' + $scope.context)
        }

      }
    }
  })
